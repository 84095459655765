import { makeVar, useReactiveVar } from '@apollo/client'
import styled                      from '@emotion/styled';
import ChevronLeftIcon             from '@mui/icons-material/ChevronLeft';
import Box                         from '@mui/material/Box';
import Divider                     from '@mui/material/Divider';
import Drawer                      from '@mui/material/Drawer';
import IconButton                  from '@mui/material/IconButton';
import * as React                  from 'react';
import { useAuth }                 from '../Context/auth';

const drawerWidth = 240;

export const drawerOpen = makeVar(false);

export const DrawerHeader = styled('div')(({ theme }) => ({
  display:        'flex',
  alignItems:     'center',
  justifyContent: 'flex-end',
  padding:        theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const DrawerRoot = styled('nav')(({ theme }) => ({
  display:       'flex',
  flexDirection: 'column',
  height:        '100%'
}));


export default function NavDrawer ({ nav: Nav, lowerNav: LowerNav, ...props }) {
  const { window } = props;
  const { isLoggedIn } = useAuth();

  const open       = useReactiveVar(drawerOpen);

  const handleDrawerToggle = () => {
    drawerOpen(!open);
  };

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <Box
        component="nav"
        sx={{ flexShrink: { sm: 0 } }}
      >
        <Drawer
          container={container}
          variant="temporary"
          open={open}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerToggle}>
              <ChevronLeftIcon />
            </IconButton>
          </DrawerHeader>
          <Divider />

          <DrawerRoot>
            <Box sx={{ flexGrow: 1 }}>
              <Nav />
            </Box>

            {LowerNav ? (
              <Box>
                <Divider />
                <LowerNav />
              </Box>
            ) : null}
          </DrawerRoot>


        </Drawer>

      </Box>

    </>
  );
}
