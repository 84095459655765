import { green, grey }                                                        from '@mui/material/colors';
import { createTheme, StyledEngineProvider, ThemeProvider as Provider } from '@mui/material/styles';
import PropTypes                                                        from 'prop-types';
// import lifecycleProgression, { lifecycleStates }                        from '../lib/LifeCycle/details';
import LinkBehavior                                                     from '../lib/LinkBehavior';

// const lcPalletColors = lifecycleStates
//   .reduce((obj, state) => {
//     obj[state] = lifecycleProgression[state].palette
//     return obj;
//   }, {})

export const theme = createTheme({
  palette:    {
    primary:   { main: '#673ab7' },
    secondary: { main: '#f50057' },
    background: {
      default: grey[200]
    }
    // ...lcPalletColors
  },
  components: {
    MuiLink:    {
      defaultProps: {
        component: LinkBehavior,
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
    },
  }
});
// console.log(theme)


function ThemeProvider ({ children }) {
  document.documentElement.setAttribute('data-color-mode', 'light')

  return (
    <StyledEngineProvider>
      <Provider theme={theme}>{children}</Provider>
    </StyledEngineProvider>
  )
}

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default ThemeProvider;
