import { gql }     from '@apollo/client';
import { graphql } from '../client';

export const GQL_QUERY_GET_NHENTAI_SESSION = gql`
  query startNHentaiSession ($id: ID!) {
    session: newLinkSession(id: $id) {
      id 
      createdAt 
      updatedAt
      
      galleryId 
      info 
      cover
      link
    }
    tags: listTags(page: {limit: 0}) {
      items {
        id
        tag
        category
        description
      }
    } 
  }
`;

export const GQL_QUERY_PROFILE = gql`
  query getProfile {
    profile {
      token
      user {
        id
        username
        firstName
        lastName
        type
        isAdmin
        createdAt
        updatedAt
      }
    }
  }
`;

export function getProfileQuery () {
  return graphql({
    query: GQL_QUERY_PROFILE
  })
}
