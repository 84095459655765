import HomeIcon                                       from '@mui/icons-material/Home';
import LoginIcon                                      from '@mui/icons-material/Login';
import SettingsIcon                                   from '@mui/icons-material/Settings';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import Button                                         from '@mui/material/Button';
import Link                                           from '@mui/material/Link';
import List                                           from '@mui/material/List';
import { useLocation }                                from 'react-router-dom';
import { useAuth }                                    from '../Context/auth';
import { handleLoginClick }                           from '../Context/auth/useRedirectToken';
import { addNHentaiBookmark }                         from '../utils/bookmark-link';
import { drawerOpen }                                 from './NavDrawer';

const _links            = {
  loggedIn:    {
    upper: [
      { text: 'Home', Icon: HomeIcon, href: '/' },
    ],
    lower: [
      { text: 'Settings', Icon: SettingsIcon, href: '/settings' },
    ],
  },
  notLoggedIn: {
    upper: [
      { text: 'Home', Icon: HomeIcon, href: '/' },
      { text: 'Login', Icon: LoginIcon, href: '/login' },
    ],
    lower: []
  }
};
const handleDrawerClose = () => {
  drawerOpen(false)
};

export function Nav () {
  const { isLoggedIn, logout } = useAuth();


  const links = isLoggedIn ? _links.loggedIn.upper : _links.notLoggedIn.upper;

  return (
    <List component="nav">
      {links.map(p => (
        <ListItemButton key={p.href} component={Link} href={p.href} onClick={handleDrawerClose}>
          <ListItemIcon>
            <p.Icon />
          </ListItemIcon>
          <ListItemText primary={p.text} />
        </ListItemButton>
      ))}
    </List>
  );
}


export function LowerNav () {
  const { isLoggedIn } = useAuth();

  const links = isLoggedIn ? _links.loggedIn.lower : _links.notLoggedIn.lower;

  return (
    <List component="nav">
      {links.map(p => (
        <ListItemButton key={p.href} component={Link} href={p.href} onClick={handleDrawerClose}>
          <ListItemIcon>
            <p.Icon />
          </ListItemIcon>
          <ListItemText primary={p.text} />
        </ListItemButton>
      ))}
      {/*{isLoggedIn ? (*/}
      <li dangerouslySetInnerHTML={{ __html: addNHentaiBookmark('Add nHentai to DoujinList') }} />
      {/*) : null}*/}
    </List>
  );
}

//dangerouslySetInnerHTML={{__html: data}}


function LoginLink ({ link, text, handleDrawerClose }) {
  const { pathname } = useLocation();

  return (
    <Button
      onClick={() => handleLoginClick(pathname)}
      sx={{ my: 2, color: 'white', display: 'block' }}
    >
      {text}
    </Button>
  )
}


