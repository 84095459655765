import { useQuery }                from '@apollo/client';
import styled                      from '@emotion/styled';
import { Autocomplete, TextField } from '@mui/material';
import Box                         from '@mui/material/Box';
import Chip                        from '@mui/material/Chip';
import Divider                     from '@mui/material/Divider';
import FormControl                 from '@mui/material/FormControl';
import InputLabel                  from '@mui/material/InputLabel';
import MenuItem                    from '@mui/material/MenuItem';
import OutlinedInput               from '@mui/material/OutlinedInput';
import Paper                       from '@mui/material/Paper';
import Select                      from '@mui/material/Select';

import { useTheme }                      from '@mui/material/styles';
import Typography                        from '@mui/material/Typography';
import Grid2                             from '@mui/material/Unstable_Grid2';
import formatDate                        from 'date-fns/format';
import { useState }                      from 'react';
import { useParams }                     from 'react-router-dom';
import { GQL_QUERY_GET_NHENTAI_SESSION } from '../data/new-link';
import noop                              from '../utils/noop';
import { ucFirst }                       from '../utils/string';

const Img       = styled('img')({
  width:  '100%',
  height: 'auto'
})
const MutedLink = styled('a')(({ theme }) => ({
  color:          theme.palette.grey[500],
  textDecoration: 'none',
  '&:hover':      {
    color: theme.palette.grey[600]
  }
}));


//       label
//       category
//       tags {
//         id
//         label
//         category
//         description
//       }

const categories = [
  'characters',
  'tags',
  'artists',
  'groups',
  'languages',
  'categories'
]

export default function NewNHentaiLink () {
  const { id }                            = useParams();
  const [ tagsSelected, setTagsSelected ] = useState([]);
  const { data, loading }                 = useQuery(GQL_QUERY_GET_NHENTAI_SESSION, {
    variables:   { id },
    onCompleted: ({ session }) => {
      setTagsSelected(session.info.tags.reduce((obj, t) => {
        if (!obj[t.category]) {
          obj[t.category] = []
        }
        obj[t.category].push(t);
        return obj;
      }, {}));
    }
  });

  if (loading) {
    return;
  }

  const filterByCat = (arr, cat) => arr.filter(({ category }) => cat === category);

  // const selectTag =

  // console.log({ tagsSelected })

  const session = data.session;
  const tags    = data.tags.items;
  const info    = session.info;
  const link    = session.link;
  const cover   = session.cover || '';
  // const categories = Object.getOwnPropertyNames(info.categories);

  // const tagsTmp = info.categories.tags.map(({ value }) => value);
  const handleTagChange = (category) => {
    return (event, newValue) => {
      if (typeof newValue === 'string') {
        // setValue({
        //   title: newValue,
        // });
      } else if (newValue && newValue.inputValue) {
        // Create a new value from the user input
        // setValue({
        //   title: newValue.inputValue,
        // });
      } else {
        setTagsSelected(t => ({ ...t, [category]: newValue }));
      }
    }
  }

  return (
    <Paper sx={{ my: 2, p: 2 }}>
      <Grid2 container spacing={2}>
        <Grid2 xs={12} sm={5}>
          <Img src={cover} alt={info.title} />
        </Grid2>
        <Grid2 xs={12} sm={7}>
          <Typography variant="h4">
            {info.title}
          </Typography>
          <Typography variant="subtitle1" component={MutedLink} href={link}>
            Gallery # {info.galleryId}
          </Typography>
          {info.pages ? (
            <Typography variant="subtitle2" component={'span'}>
              &nbsp;| {info.pages} Pages
            </Typography>
          ) : null}
          {info.uploadedAt ? (
            <Typography variant="subtitle2" component={'span'}>
              &nbsp;| Uploaded {formatDate(new Date(info.uploadedAt), 'PPP')}
            </Typography>
          ) : null}
          <Divider />

          {categories.map(c => (
            <TagSelect
              key={c}
              label={ucFirst(c)}
              category={c}
              onChange={handleTagChange(c)}
              selectedTags={tagsSelected[c] || []}
              tags={filterByCat(tags, c)}
              sx={{ my: 2 }}
            />
          ))}

          <Divider />

          {/*<MultipleSelectChip />*/}


        </Grid2>
      </Grid2>
      {/*<Pre>*/}
      {/*  {JSON.stringify(data ? data.session : {}, null, 2)}*/}
      {/*</Pre>*/}
    </Paper>
  );
}



function TagSelect ({ category, label, selectedTags, onChange = noop, tags, sx = {} }) {
  return (
    <Autocomplete
      multiple
      id="tags-outlined"
      options={tags}
      clearOnBlur
      selectOnFocus
      handleHomeEndKeys
      getOptionLabel={(option) => option.tag}
      value={selectedTags}
      onChange={onChange}
      filterSelectedOptions
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          // placeholder={label}
        />
      )}
      sx={sx}
    />
  );
}

// function TagSelect ({ category, label, selectedTags, onChange = noop, tags }) {
//   const theme = useTheme();
//
//   // tags = testData.info.meta.tags.map(({ value }) => value);
//   // console.log(tags)
//
//   return (
//     <FormControl sx={{ m: 1, width: '100%' }}>
//       <InputLabel id={`${category}-select-label`}>{label}</InputLabel>
//       <Select
//         labelId={`${category}-select-label`}
//         id={`${category}-select`}
//         multiple
//         value={selectedTags}
//         onChange={onChange}
//         input={<OutlinedInput id={`${category}-select-input`} label={label} autoComplete="off" />}
//         renderValue={(selected) => (
//           <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
//             {selected.map(({ id, tag }) => (
//               <Chip key={id} label={tag} />
//             ))}
//           </Box>
//         )}
//         MenuProps={MenuProps}
//       >
//         {tags.map((tag) => (
//           <MenuItem
//             key={tag.id}
//             value={tag}
//             style={getStyles(tag.tag, selectedTags, theme)}
//           >
//             {tag.tag}
//           </MenuItem>
//         ))}
//       </Select>
//     </FormControl>
//   );
// }



const ITEM_HEIGHT      = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps        = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width:     250,
    },
  },
};

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

function getStyles (name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
      ? theme.typography.fontWeightRegular
      : theme.typography.fontWeightMedium,
  };
}

function MultipleSelectChip () {
  const theme                         = useTheme();
  const [ personName, setPersonName ] = useState([]);

  const handleChange = (value) => {
    console.log(value)
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: '100%' }}>
        <InputLabel id="multiselect-label">Tags</InputLabel>
        <Select
          labelId="multiselect-label"
          id="multiselect-chip"
          multiple
          value={personName}
          onChange={e => handleChange(e.target.value)}
          input={<OutlinedInput id="select-multiselect-chip" label="Chip2" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {names.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, personName, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
