import Box                         from '@mui/material/Box';
import Container                   from '@mui/material/Container';
import CssBaseline                 from '@mui/material/CssBaseline';
// import { styled }                  from '@mui/material/styles';
import { useAuth }                 from '../Context/auth';
import { LayoutContextProvider }   from './context';
import Copyright                   from './Copyright';
// import Footer                      from './Footer'
// import IssueFooter                 from './IssueFooter';
import MenuBar                     from './MenuBar';
import NavDrawer, { DrawerHeader } from './NavDrawer';

export { useLayout, LayoutContext } from './context';

// const RootBG = styled(Box)(({ theme }) => ({
//   display:         'flex',
//   backgroundColor: theme.palette.grey[100],
//   minHeight:       '100vh'
// }));

export default function Layout ({ nav, lowerNav, children }) {
  const { isLoggedIn } = useAuth();


  return (
    <LayoutContextProvider>
      {/*<RootBG>*/}
      <CssBaseline />
      <MenuBar />
      <NavDrawer nav={nav} lowerNav={lowerNav} />

      <Container maxWidth="md">
        <DrawerHeader />

        {children}

        <Box py={3} mb={5} display="flex" flexDirection="column" alignItems={'center'}>
          {/*{isLoggedIn ? (*/}
          {/*  <IssueFooter />*/}
          {/*) : null}*/}
          {/*<Footer />*/}
          <Copyright />
        </Box>
      </Container>
      {/*</RootBG>*/}
    </LayoutContextProvider>
  );
}
