import { useReactiveVar }                        from '@apollo/client';
import MenuIcon                                  from '@mui/icons-material/Menu';
import { AppBar as MuiAppBar, styled, useTheme } from '@mui/material';
import Box                                       from '@mui/material/Box';
import IconButton                                from '@mui/material/IconButton';
import Toolbar                                   from '@mui/material/Toolbar';
import Typography                                from '@mui/material/Typography';
import { useAuth }                               from '../Context/auth';
import { drawerOpen }                            from './NavDrawer';
import UserIcon                                  from './UserIcon';

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  // zIndex: theme.zIndex.drawer + 1,
}))


export default function MenuBar () {
  const theme                  = useTheme();
  const { isLoggedIn, logout } = useAuth();

  const isDrawerOpen = useReactiveVar(drawerOpen);
  // const setOpen = useSetRecoilState(drawerOpenAtom);

  const handleDrawerToggle = () => {
    // setOpen(o => !o);
    drawerOpen(!isDrawerOpen)
  };


  return (
    <AppBar position="absolute">
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerToggle}
          edge="start"
          size="large"
          sx={{ mr: 2 }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap sx={{ flexGrow: 1 }}>
          DoujinList
        </Typography>



        <Box sx={{ flexGrow: 0, pr: 1 }}>
          {isLoggedIn
           ? <UserIcon />
           : <Box width="44px" />
          }
        </Box>
      </Toolbar>
    </AppBar>
  );
}
