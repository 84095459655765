const _GQL_ENDPOINTS = {
  dev:        'http://localhost:4000/graphql',
  production: 'https://api.mat-interviews.educ.aws.uci.edu/graphql',
};

export const environment = process.env.REACT_APP_STAGE || 'dev';
export const apiServer = _GQL_ENDPOINTS[environment];
export const dataEndpoint = apiServer;

export const issuesLink = 'https://github.com/rhettl/doujinlist/issues';
