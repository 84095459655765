import { Route, Routes } from 'react-router-dom';
import Context           from './Context';
import { AuthGateway }   from './Context/auth';
import Layout            from './Layout';
import { LowerNav, Nav } from './Layout/Nav';
import './App.css';
import CatchAll          from './other-pages/CatchAll';
import Loading           from './other-pages/Loading';
import Login             from './other-pages/Login';
import NewNHentaiLink    from './Newlink/NHentai'

function App() {
  return (
    <Context>
      <AuthGateway loading={<Loading />} login={<Login />}>

        <Layout nav={Nav} lowerNav={LowerNav}>

          <Routes>
            <Route path="/new-link/nh/:id" element={<NewNHentaiLink />} />
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<CatchAll />} />
          </Routes>

        </Layout>
      </AuthGateway>
    </Context>
  );
}

export default App;
