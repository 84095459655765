export default function noop (...args) {
  console.trace('Noop was called', args)
}
export function makeNoop (msg, ...args1) {
  return (args2) => {
    console.log(msg, ...args1);
    noop(...args2);
  }
}

