
const addNHentaiBookmarkScript = `
(function ({ info, cover, galleryId }) {
  return fetch('http://localhost:4000/', {
    method: 'POST',

    headers: {
      'Content-Type': 'application/json'
    },

    body: JSON.stringify({
      query:     \`
    mutation startNewSession ($info: String!, $cover: String, $galleryId: String!) {
      session: startNewNHentaiSession (info: $info, cover: $cover, galleryId: $galleryId)
    }
    \`,
      variables: {
        info,
        cover,
        galleryId
      }
    })
  })
    .then(resp => resp.json())
    .then(({ data: { session } }) => session)
})({
  info: document.querySelector('#info-block').innerHTML,
  cover: document.querySelector('#bigcontainer img').src,
  galleryId: location.pathname.replace(/\\/$/, '').split(/\\//g).pop(),
})
  .then(session => location.href = 'http://localhost:3000/new-link/nh/' + session)
  .catch(err => {
    console.error(err);
    alert('failed to add session');
  })
`

export function addNHentaiBookmark (text) {
  return `<a href="javascript:${addNHentaiBookmarkScript}">${text}</a>`;
}
