import { gql }                      from '@apollo/client';
import { graphql } from '../client';

export const GQL_MUTATION_LOGIN = gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      token
      user {
        id
        username
        lastName
        firstName
        type
        isAdmin
        updatedAt
        createdAt
      }
    }
  }
`;
