import Box              from '@mui/material/Box';
import Button           from '@mui/material/Button';
import { grey as gray } from '@mui/material/colors';
import Container        from '@mui/material/Container';
import Paper            from '@mui/material/Paper';
import TextField        from '@mui/material/TextField';
import Typography       from '@mui/material/Typography';
import { encode }       from 'base-64';
import { useState }     from 'react';
import { useLocation }  from 'react-router-dom';
import { useAuth }      from '../Context/auth';


export default function Login () {
  const { login } = useAuth()
  const location  = useLocation();
  const [ errorFeedback, setErrorFeedback ] = useState('');

  // console.log('loginEl', location.state);
  const authMessage = location.state && location.state.authErrorMessage;

  const onSubmit = async (e) => {
    e.preventDefault();

    const elementsArray          = [ ...e.target.elements ]
    const { username, password } = elementsArray.reduce((acc, elem) => {
      if (elem.id) acc[elem.id] = elem.value
      return acc
    }, {})

    let res;
    try {
      await login({ username, password: encode(password) });
    } catch (err) {
      setErrorFeedback(err.message);
      throw err;
    }
  };

  return (
    <Box sx={{ backgroundColor: gray[200], height: '100vh' }}>

      <Container maxWidth="sm" sx={{ my: 5, mx: 'auto' }}>
        <Paper elevation={1} sx={{ p: 3 }}>
          <Typography sx={{ mb: 3 }} variant="h4">Login</Typography>

          {authMessage ? (
            <Typography variant={'subtitle1'} sx={{ color: 'warning.main' }}>{authMessage}</Typography>
          ) : null}

          <form onSubmit={onSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              autoFocus
              id="username"
              label="Username"
              required
              autoComplete="doujinlist-username"
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="password"
              type="password"
              label="Password"
              required
              autoComplete="doujinlist-password"
            />

            <Typography variant='subtitle2' sx={{ color: 'error.main' }}>
              {errorFeedback}
            </Typography>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              name="login_button"
              value="Login"
              sx={{ mt: 3, mx: 0, mb: 2 }}
            >
              Login
            </Button>
          </form>

        </Paper>
      </Container>
    </Box>
  );
};
